<template>
  <div>
    <v-data-table
      :items="items"
      :headers="headers"
      v-bind="$attrs"
      v-on="$listeners"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
    >
      <template v-slot:item.approvalKey="{ item }">
        <router-link :to="{ name: 'approval', params: { id: item.id } }">
          {{ item.approvalKey }}
        </router-link>
      </template>
      <template v-slot:item.approvalType="{ item }">
        {{ item.approvalType.name }}
      </template>
      <template v-slot:item.approvalStage="{ item }">
        <v-chip>{{ item.approvalStage.name }}</v-chip>
      </template>
      <template v-slot:item.assignedToParticipant="{ item }">
        {{ item.assignedToParticipant ? item.assignedToParticipant.fullName : "" }}
      </template>
      <template v-slot:item.submittedByParticipant="{ item }">
        {{ item.submittedByParticipant ? item.submittedByParticipant.fullName : "" }}
      </template>
      <template v-slot:item.approved="{ item }">
        <span v-if="item.approved"
          ><v-chip color="primary">{{ $i18n.translate("Approved") }}</v-chip
          ><br />{{ item.approvedDate }}</span
        >
        <span v-else-if="item.rejected"
          ><v-chip color="primary">{{ $i18n.translate("Rejected") }}</v-chip
          ><br />{{ item.rejectedDate }}<br />{{ item.rejectedReason }}</span
        >
      </template>
      <template v-slot:item.effective="{ item }">
        <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
        <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.approved">
          Approved on
          {{ item.approvedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </span>
        <span v-else-if="item.rejected">
          Rejected on
          {{ item.rejectedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
          <span v-if="item.rejectedReason"> because &quot;{{ item.rejectedReason }}&quot;</span>
        </span>
        <span v-else-if="item.closed">
          Closed on
          {{ item.closedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </span>
        <span v-else>
          Open
          {{ item.closedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
        </span>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ item.createdDate | formatDateFromNow }}
      </template>
      <template v-slot:item.updatedDate="{ item }">
        {{ item.updatedDate | formatDateFromNow }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MorOrdersTable",
  props: {
    value: Array,
    open: { type: Boolean, default: null },
    closed: { type: Boolean, default: null },
    approvalType: { type: Object, default: null },
    filters: Object
  },
  data() {
    return {
      loading: true,
      errors: {},
      items: [],
      headers: [
        {
          value: "approvalKey",
          text: "Key",
          sortable: true
        },
        {
          value: "name",
          text: "Name",
          sortable: true
        },
        {
          value: "approvalType",
          text: "Type",
          sortable: true
        },
        {
          value: "approvalStage",
          text: "Stage",
          sortable: true
        },
        {
          value: "assignedToParticipant",
          text: "Assigned To",
          sortable: true
        },
        {
          value: "submittedByParticipant",
          text: "Submitted By",
          sortable: true
        },
        {
          value: "status",
          text: "Status",
          sortable: true
        },
        {
          value: "createdDate",
          text: "Age",
          sortable: true
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },
      localApprovalType: this.approvalType,
      isInitialFetchComplete: false
    };
  },
  methods: {
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let f = Object.assign({}, this.filters);
      if (this.open != null) {
        f.isOpen = this.open;
      }
      if (this.closed != null) {
        f.isClosed = this.closed;
      }
      if (this.localApprovalType != null) {
        f.approvalType = { id: this.localApprovalType.id };
      }
      if (f.approvalType) {
        f.approvalType = { id: f.approvalType.id };
        if (f.approvalStage) {
          f.approvalStage = { id: f.approvalStage.id };
        }
      } else {
        delete f.approvalStage;
      }
      if (f.assignedToMe === true) {
        delete f.assignedToMe;
        f.assignedToParticipant = { id: this.selectedParticipant.id };
      } else {
        delete f.assignedToMe;
        delete f.assignedToParticipant;
      }
      return this.$api
        .post(
          "/api/approvals/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          f
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.$emit("total", this.total);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchApprovalType(key) {
      return this.$api.post("/api/approvalTypes/search?size=1", { approvalTypeKey: key }).then(({ data }) => {
        this.localApprovalType = data && data.content.length == 1 ? data.content[0] : null;
      });
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    }
  },
  created() {
    if (this.$route.query.approvalTypeKey != undefined) {
      this.fetchApprovalType(this.$route.query.approvalTypeKey).then(approvalType => {
        if (approvalType) {
          this.filterByPromotionTypes.push(approvalType);
        }
        this.onSubmit();
        this.isInitialFetchComplete = true;
      });
    } else {
      this.onSubmit();
      this.isInitialFetchComplete = true;
    }
  },
  watch: {
    options: {
      handler() {
        if (this.isInitialFetchComplete) {
          this.getData();
        }
      },
      deep: true
    },
    filters: {
      handler() {
        this.getData();
      },
      deep: true
    },
    approvalType(newValue) {
      this.localApprovalType = newValue;
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedParticipant"])
  }
};
</script>
