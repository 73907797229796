<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Approvals</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-checkbox
        hide-details
        class="mr-6"
        :label="$i18n.translate('Assigned to Me?')"
        v-model="filters.assignedToMe"
      ></v-checkbox>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-6"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Approvals')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" icon v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <ApprovalTypeField
                  dense
                  v-model="filters.approvalType"
                  :label="$i18n.translate('Approval Type')"
                  clearable
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ApprovalStageField
                  dense
                  v-if="filters.approvalType"
                  :approvalType="filters.approvalType"
                  v-model="filters.approvalStage"
                  :label="$i18n.translate('Approval Stage')"
                  clearable
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-tabs v-model="tab" v-else>
        <v-tab>
          <v-badge v-if="tabTotals[0]" :content="tabTotals[0]">{{ $i18n.translate("Open") }} </v-badge>
          <span v-else>{{ $i18n.translate("Open") }}</span>
        </v-tab>
        <v-tab>
          <v-badge v-if="tabTotals[1]" :content="tabTotals[1]">{{ $i18n.translate("Closed") }} </v-badge>
          <span v-else>{{ $i18n.translate("Closed") }}</span>
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <ApprovalsTable @total="total => onTotal(total, 0)" :filters="filters" :open="true" />
          </v-tab-item>
          <v-tab-item eager>
            <ApprovalsTable @total="total => onTotal(total, 1)" :filters="filters" :closed="true" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import ApprovalStageField from "../../fields/ApprovalStageField.vue";
import ApprovalTypeField from "../../fields/ApprovalTypeField.vue";
import ApprovalsTable from "../../tables/ApprovalsTable.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApprovalsTable, ApprovalTypeField, ApprovalStageField },
  name: "Approvals",
  metaInfo: {
    title: "Approvals"
  },
  data() {
    return {
      loading: false,
      tab: 0,
      search: "",
      filters: {
        assignedToMe: false,
        keyword: ""
      },
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      tabTotals: [null, null]
    };
  },
  methods: {
    onTotal(total, tabIndex) {
      this.tabTotals[tabIndex] = total;
      this.$forceUpdate();
    },
    onSubmit() {
      this.filters.keyword = this.search;
    },
    onClear() {
      this.search = "";
      this.onSubmit();
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Clients"),
        to: { name: "clients" },
        exact: true
      },
      {
        text: this.selectedClient.name,
        to: {
          name: "client",
          params: { id: this.selectedClient.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Programs"),
        to: { name: "programs" },
        exact: true
      },
      {
        text: this.selectedProgram.name,
        to: {
          name: "program",
          params: { id: this.selectedProgram.id },
          exact: true
        }
      },
      { text: "Approvals" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  }
};
</script>
