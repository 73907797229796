<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="approvalStages"
    item-value="id"
    item-text="name"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ApprovalStageField",
  props: {
    value: [Object, Array],
    approvalType: Object,
    approver: Object
  },
  data: () => ({
    approvalStages: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      if (this.approver) {
        this.approvalStages = this.approver.approvalStages;

        // need to insert current stage, if it doesn't exist
        let found = this.approvalStages.find(approvalStage => approvalStage.id == this.value.id);
        if (!found) {
          let index = 0;
          for (let i = 0; i < this.approvalStages.length; i++) {
            let approvalStage = this.approvalStages[i];
            if (approvalStage.rankOrder > this.value.rankOrder) {
              index = i;
            } else {
              break;
            }
          }
          this.approvalStages.splice(index, 0, this.value);
        }
      } else if (this.approvalType) {
        ApiService.get("/api/approvalStages/byApprovalTypeId/" + this.approvalType.id).then(({ data }) => {
          this.approvalStages = data;
        });
      } else {
        this.approvalStages = [];
      }
    }
  },
  watch: {
    approvalType: {
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
